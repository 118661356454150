import { createStore } from 'pinia';
import { PatentSubscribe } from './api';

export const usePatentSubscribeStore = createStore({
  id: 'patent',
  state: () => ({
    current: null as null | PatentSubscribe,
  }),
  // optional getters
  actions: {
    setCurrent(current: null | PatentSubscribe) {
      this.current = current;
    },
  },
});
