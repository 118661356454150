










































import { defineComponent, toRefs, watch } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { getPatentSubscribes, PatentSubscribe } from './api';
import { usePatentSubscribeStore } from './store';

export default defineComponent({
  setup(_, { root }) {
    const { state } = useMainStore();
    const { setCurrent } = usePatentSubscribeStore();

    const { result: subscribes, refresh } = useApi((page?: number, size?: number) =>
      getPatentSubscribes(),
    );

    return {
      subscribes,
      toDetail(item: PatentSubscribe) {
        setCurrent(item);
        root.$router.push({ name: 'PatentSubscribeDetail' });
      },
    };
  },
});
