import { request } from '@/util/request';
import { PatentSubscribe } from './model';

export * from './model';

export function getPatentSubscribes() {
  return request<PatentSubscribe[]>('/strapi/patent/subscribes/');
}

export function cancelSubscribePatent(no: string) {
  return request(`/strapi/patent/subscribes/${no}`, {
    method: 'DELETE',
  });
}

export function renewSubscribe(no: string) {
  return request(`/strapi/patent/renew/${no}`, {
    method: 'PUT',
  });
}
